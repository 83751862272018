.pdf-navigator {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: white;
  height: 3em;
  width: 7.5em;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  /* opacity: 0; */
  transition: opacity ease-in-out 0.2s;
}

/* .pdf-navigator:hover {
  opacity: 1;
} */

.pdf-page-container {
  border: 1px solid black;
  margin: 5px 0;
  border-radius: 3px;
}

.mci-pdf-preview {
  height: 575px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .pdf-navigator {
    opacity: 1;
  }

  .mci-pdf-preview {
    height: 450px;
    margin-top: 40px;
  }
}
