.post-card {
  background-color: white;
  border: none;
  border-radius: 0;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  /* remove side margin later */
  margin-bottom: 1em;
  border-radius: 6px;
  padding-bottom: 1em;
}

.post-header {
  display: flex;
  align-content: center;
  padding: 0.5em 0.8em;
  border-radius: 50%;
}

.post-header-image {
  height: 3.5em;
  width: 3.5em;
  border-radius: 50%;
  border: 1px solid green;
}

.post-info-container {
  width: 140%;
  /* margin: 0 1em; */
}

.post-content {
  padding: 10px 20px;
}

.post-poster {
  font: normal normal normal bold 15px/19px Roboto, sans-serif;
  /* margin-top: 7px; */
  color: #4e4e4e;
}

.post-title {
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0px;
  color: #404040;
  margin-bottom: 0.5em;
  overflow-wrap: break-word;
}
.post-description {
  font: normal normal normal 16px/25px Roboto;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  position: relative;
  color: #404040;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.vertical-divider {
  border-left: 2px solid rgba(206, 205, 198, 0.52);
}

.post-box {
  border-radius: 18px !important;
  background-color: rgb(221 253 247) !important;
  margin-top: 8px;
  width: 115%;
  height: 25px;
}
.post-box-head {
  display: flex;
  align-content: center;
  border-radius: 50%;
}

.upload-body {
  width: 68px;
  background-color: #e8e8e8;
  border-radius: 8px !important;
  margin-left: 8px;
}
.upload-plus {
  width: 68px;
  height: 77px;
  background-color: white;
  border-radius: 8px !important;
  margin-left: 8px;
  border: 1px dashed #e8e8e8;
}
.sub-head {
  color: black !important;
}
.menu-head {
  font: 14px/20px roboto;
  margin-top: -4px;
  margin-bottom: -4px;
}

.break-word {
  word-break: break-all;
}

.postSuccess {
  height: 40%;
  /* background-color: #1e8a79; */
  text-align: center !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-top: 150px !important;
  max-width: 500px !important;
}
.pubDelete {
  height: 45%;
  text-align: center !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-top: 150px !important;
  max-width: 500px !important;
}

.post-box-item {
  background-color: white;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  width: 33%;
  display: flex;
  align-items: center;
}

.post-box-item:hover {
  background-color: rgb(221 253 247);
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.post-box-item-text {
  color: #7e7d7a85;
  font: bold 10px / 20px roboto;
  margin-left: 1em;
  height: 100%;
}

.post-box-item-label {
  color: rgb(30, 138, 121);
  word-break: break-all;
}

.post-modal {
  /* border-radius: 8px; */
  font-family: "roboto", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.post-modal-header {
  background-color: #469b8d;
  color: white;
  height: 5em;
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.post-modal-body {
  padding: 1em 2em;
  overflow: auto;
}

.post-modal-help-text-header {
  color: #888888;
  font-size: 1.1em;
}

.post-modal-help-text {
  white-space: pre-wrap;
  font-size: 0.8em;
  color: #b1b1b1;
  margin: 1em 0.2em;
}

.remove-margin {
  margin: 0 !important;
}

.post-modal-attachments {
  margin: 2em 0;
}

.post-modal-file-container {
  margin: 1em 0;
  display: grid;
  row-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
}

.post-modal-file-upload {
  border: 1px dashed #b1b1b1;
  border-radius: 8px;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-modal-file {
  border-radius: 8px;
  height: 150px;
  width: 150px;
  position: relative;
}

.post-modal-file-delete {
  position: absolute;
  top: -8px;
  right: -8px;
}

.post-modal-file-edit {
  position: absolute;
  bottom: 5px;
  left: 5px;
  border-radius: 10px;
  background-color: white;
  width: 60px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.8em;
  padding: 0 3px;
}

.create-post-button {
  border: 1px rgb(30, 138, 121) solid;
  border-radius: 20px;
  height: 1.9em;
  width: 10em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 5px;
  padding-right: 18px;
  margin-top: 1em;
  color: rgb(30, 138, 121);
  cursor: pointer;
}

.post-modal-submit {
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0px 3px #00000029;
  margin: 0.6em 0;
}

.post-modal-submit-button {
  background-color: #469b8d;
  color: white;
  border-radius: 30px;
  height: 2.5em;
  width: 13em;
}

.post-modal-form {
  margin-top: 1em;
}

.post-modal-form-field {
  margin: 1em 0;
}

.create-post-button:hover {
  background-color: rgb(30, 138, 121);
  color: white;
}
.create-post-button-click {
  background-color: rgb(30, 138, 121);
  color: white;
}

.create-post-button-text {
  font-size: 0.7em;
  font-family: sans-serif;
  font-weight: bold;
}

.create-post-button-icon {
  height: 15px;
}
.create-post-head-button {
  border-radius: 5px;
  height: 1.9em;
  width: 10em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 5px;
  padding-right: 18px;
  cursor: pointer;
  padding-top: 25px;
}
.create-post-head-button-text {
  font-size: 0.7em;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.create-post-head-button-icon {
  height: 15px;
  margin-bottom: 20px;
}
.create-post-m {
  margin-bottom: 5px;
}
.post-button-dropdown-container {
  border: 0.5px rgb(0 0 0 / 0.2) solid;
  background-color: white;
  z-index: 2;
  border-radius: 5px;
  height: 250px;
  width: 270px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
  padding: 10px;
  position: absolute;
  margin-top: 10px;
}

.post-button-dropdown-item {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.post-button-dropdown-item:hover {
  background-color: rgb(221 253 247);
}
.post-button-dropdown-header {
  margin-bottom: 7px;
}

.post-button-dropdown-header-text {
  font-size: 0.7em;
  font-weight: bold;
  font-family: sans-serif;
  color: rgb(30, 138, 121);
  margin-left: 10px;
}

.post-button-dropdown-text {
  color: #7e7d7a85;
  font-size: 0.6em;
}
.error-title-css {
  margin: 5px 0px 0px 22px !important;
  color: red;
  background-color: white;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.attach-model {
  width: 40%;
  height: 70%;
}
.attach-model-res {
  width: 95%;
  height: 70%;
}

@media screen and (max-width: 728px) {
  .post-modal-file-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 425px) {
  .post-button-dropdown-container {
    left: -50px;
  }

  .post-box-item-label {
    height: 25%;
    line-height: 15px;
  }
}

.ql-editor {
  min-height: 80px;
  border-color: "#1e8a79 !important";
}
:hover .ql-editor {
  border-color: "#1e8a79 !important";
}
:focus .ql-editor {
  border-color: "#1e8a79 !important";
}
