* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font: normal normal medium Roboto;
  color: #333;
  /* font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Small devices (landscape phones, 576px and up) */

/* @media (min-width: 768px) {
  .row {
    display: -webkit-inline-box;
  }
} */
.is-momentumScrollable {
  -webkit-overflow-scrolling: touch;
}
.MuiTabs-flexContainer {
  display: "flex";
  justify-content: "space-between";
}

@media (min-width: 576px) {
  .search-button {
    font-size: 40px;
    margin-left: 89%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 83%;
    top: -8px;
    position: absolute;
  }
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 62px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .attachment-video {
    width: 100%;
  }
  .mobile-help-form {
    background-color: white;
    width: 325px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
    height: 50px;
    padding: 10px;
  }
  .feedModel {
    width: 80%;
    height: 999px;
    overflow-y: scroll;
  }
  .profileModel {
    width: 100%;
    height: 99px;
    overflow-y: scroll;
  }
  .dashboard-videoAttachment {
    height: 150px;
  }
  .feedsLikeModel {
    width: 95%;
    /* overflow-y: scroll !important; */
    height: 100%;
  }
  .aboutme-title {
    font: normal normal normal 18px/20px Roboto;
    letter-spacing: 0px;
    word-break: break-all;
    margin-left: 10px;
  }
  .help-form {
    background-color: white;
    width: 325px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .attachment {
    /* height: 100%; */
    max-height: 500px;
    width: 100%;
  }
  .attachment-2 {
    width: 100%;
    max-height: 500px;
    /* height: 100%; */
  }
  .attachment-3 {
    max-height: 500px;
    /* height: 100%;   */
    width: 100%;
    /* border:1px solid #f1f1f1; */
  }
  .uploadMci {
    margin-left: 18px;
    padding: "0px 0px 0px 0px";
    left: -12px;
    border-radius: "9px";
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 0.8em;
  }

  .signIn-header {
    font-size: 20px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
}

@media (min-width: 425px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 62px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 89%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 83%;
    top: -8px;
    position: absolute;
  }
  .row:before,
  .row:after {
    display: inline-block !important;
  }
  .feedsLikeModel {
    width: 95%;
    /* overflow-y: scroll !important; */
    height: 100%;
  }
  .mobile-help-form {
    background-color: white;
    width: 325px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
    height: 50px;
    padding: 10px;
  }
  .dashboard-audioAttachment {
    width: 160px;
  }
  .feed-attachments-2 {
    display: contents;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(80px, min-content);
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
  }

  .feed-attachments-3 {
    display: contents;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 300px;
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
    /* margin-bottom: 120px; */
  }
  .dashboard-videoAttachment {
    height: 150px;
  }
  .feedModel {
    width: 100%;
    overflow-y: scroll !important;
    height: 999px;
    -webkit-overflow-scrolling: scroll;
  }
  .aboutme-title {
    font: normal normal normal 18px/20px Roboto;
    letter-spacing: 0px;
    word-break: break-all;
    margin-left: 10px;
  }
  .help-form {
    background-color: white;
    width: 325px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 0.7em;
  }
  .attachment {
    /* height: 100%;   */
    max-height: 500px;
    width: 100%;
    /* border:1px solid #f1f1f1; */
  }
  .attachment-3 {
    /* height: 100%;  */
    width: 100%;
    max-height: 500px;
    /* border:1px solid #f1f1f1; */
  }
  .attachment-2 {
    width: 100%;
    max-height: 500px;
    /* height: 100%; */
  }
  .attachment-video {
    width: 100%;
  }
  .uploadMci {
    margin-left: 18px;
    padding: "0px 0px 0px 0px";

    border-radius: "9px";
  }
  .feed-title {
    font: normal normal bold 12px/24px Roboto;
    letter-spacing: 0px;
    color: #404040;
    margin-bottom: 0.5em;
  }
  .responsive-registration-tabs {
    margin-left: "5px";
    position: "relative";
    top: "3px";
    font-size: 11px;
    font: "normal normal normal  roboto";
  }
  .signIn-header {
    font-size: 20px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
}
@media (min-width: 320px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 62px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 86%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 80%;
    top: -8px;
    position: absolute;
  }
  .row:before,
  .row:after {
    display: inline-block !important;
  }
  .feedsLikeModel {
    width: 95%;
    /* overflow-y: scroll !important; */
    height: 100%;
  }
  .dashboard-audioAttachment {
    width: 160px;
  }
  .feed-attachments-2 {
    display: contents;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(80px, min-content);
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
  }

  .feed-attachments-3 {
    display: contents;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 300px;
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
    /* margin-bottom: 120px; */
  }
  .dashboard-videoAttachment {
    height: 150px;
  }
  .mobile-help-form {
    background-color: white;
    width: 93%;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
    height: 50px;
    padding: 10px;
  }
  .help-mobile {
    width: 89%;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 1px solid black;
    margin-right: 18px;
    padding: 5px;
  }
  .feedModel {
    width: 100%;
    overflow-y: scroll !important;
    height: 999px;
    -webkit-overflow-scrolling: auto;
  }
  .aboutme-title {
    font: normal normal normal 18px/20px Roboto;
    letter-spacing: 0px;
    word-break: break-all;
    margin-left: 10px;
  }
  .help-form {
    background-color: white;
    width: 93%;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .attachment {
    /* height: 100%;  */
    width: 100%;
    max-height: 500px;

    /* border:1px solid #f1f1f1; */
  }
  .attachment-3 {
    /* height: 10%;   */
    width: 100%;
    max-height: 500px;

    /* border:1px solid #f1f1f1; */
  }
  .attachment-2 {
    width: 100%;
    /* height: 100%; */
    max-height: 500px;
  }
  .form-check-label {
    font-size: 0.7em;
  }
  .uploadMci {
    padding: "0px 0px 0px 0px";
    border-radius: "9px";
  }
  .responsive-registration-tabs {
    margin-left: "5px";
    position: "relative";
    top: "3px";
    font-size: 11px;
    font: "normal normal normal  roboto";
  }

  .signIn-header {
    font-size: 20px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
}
@media (min-width: 576px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 62px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 89%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 83%;
    top: -8px;
    position: absolute;
  }
  .row:before,
  .row:after {
    display: inline-block !important;
  }
  .feedsLikeModel {
    width: 95%;
    /* overflow-y: scroll !important; */
    height: 100%;
  }
  .dashboard-audioAttachment {
    width: 160px;
  }
  .feed-attachments-2 {
    display: contents;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(80px, min-content);
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
  }

  .feed-attachments-3 {
    display: contents;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 300px;
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
    /* margin-bottom: 120px; */
  }
  .dashboard-videoAttachment {
    height: 150px;
  }
  .aboutme-title {
    font: normal normal normal 18px/20px Roboto;
    letter-spacing: 0px;
    word-break: break-all;
    margin-left: 10px;
  }
  .help-mobile {
    width: 96%;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 1px solid black;
    margin-right: 18px;
    padding: 5px;
  }
  .mobile-help-form {
    background-color: white;
    width: 325px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
    height: 50px;
    padding: 10px;
  }
  .feedModel {
    width: 100%;
    overflow-y: scroll !important;
    height: 999px;
  }
  .help-form {
    background-color: white;
    width: 325px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .attachment {
    /* height: 100%;  */
    width: 100%;
    /* border:1px solid #f1f1f1; */
  }
  .attachment-video {
    width: 100%;
    height: 10%;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 0.7em;
  }
  .uploadMci {
    padding: "0px 0px 0px 0px";
    left: -12px;
    border-radius: "9px";
  }

  .signIn-header {
    font-size: 20px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  .responsive-registration-tabs {
    margin-left: "5px";
    position: "relative";
    top: "3px";
    font-size: 11px;
    font: "normal normal normal  roboto";
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 54px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 92%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 87%;
    top: -8px;
    position: absolute;
  }
  .feedsLikeModel {
    width: 100%;
    /* overflow-y: scroll !important; */
    height: 100%;
  }
  .help-mobile {
    width: 96%;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 1px solid black;
    margin-right: 18px;
    padding: 5px;
  }
  .mobile-help-form {
    background-color: white;
    width: 500px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
    height: 50px;
    padding: 10px;
  }
  .row:before,
  .row:after {
    display: inline-block !important;
  }
  .attachment {
    /* height: 100%; */
    width: 100%;
  }
  .aboutme-title {
    font: normal normal normal 18px/20px Roboto;
    letter-spacing: 0px;

    margin-left: 10px;
  }
  .help-form {
    background-color: white;
    width: 500px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .uploadMci {
    padding: "0px 0px 0px 0px";
    left: -12px;
    border-radius: "9px";
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 0.7em;
  }
  .signIn-header {
    font-size: 20px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  .responsive-registration-tabs {
    margin-left: "3px";
    position: "relative";
    font-size: 9px;
    font: "normal normal normal  roboto";
  }
  .form-navigation-element {
    display: inline-block;
    flex-grow: 1;
    height: 100%;
    padding: 6px;
    color: black;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 54px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 92%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 87%;
    top: -8px;
    position: absolute;
  }
  .uploadMci {
    padding: "0px 0px 0px 0px";
    left: -12px;
    border-radius: "9px";
  }
  .aboutme-title {
    font: normal normal normal 20px/20px Roboto;
    letter-spacing: 0px;

    margin-left: 10px;
  }
  .help-form {
    background-color: white;
    width: 500px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .search-header {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 54px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-tab {
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 158px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button {
    font-size: 40px;
    margin-left: 92%;
    top: -8px;
    position: absolute;
  }
  .close-button {
    font-size: 40px;
    margin-left: 87%;
    top: -8px;
    position: absolute;
  }
  .aboutme-title {
    font: normal normal normal 17px/18px Roboto;
    letter-spacing: 0px;

    margin-left: 10px;
  }
  .dashboard-audioAttachment {
    width: 100%;
  }

  .feed-attachments-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(500px, auto);
    grid-auto-rows: 150px;
    gap: 5px;
  }
  .feed-attachments-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(80px, min-content);
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
  }

  .feed-attachments-3 {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 300px;
    grid-auto-rows: 150px;
    gap: 5px;
    /* border: 1px solid #c1c1c1; */
    /* margin-bottom: 120px; */
  }
  .help-form {
    background-color: white;
    width: 500px;
    height: 50px;
    padding-left: 10px;
    border-radius: 6px;
    border: 1px solid #6a6a6a;
  }
  .help-mobile {
    width: 337px;
    height: 50px;
    margin-top: 17px;
    border-radius: 6px;
    border: 1px solid black;
    margin-right: 18px;
  }
  .help-phone-input {
    margin-top: "1.1em";
    padding-left: "5px";
    background: "inherit";
    border-radius: "6px";
    width: "343px";
    height: "50px";
    border: "1px solid #8a8a8a";
  }
  .responsive-registration-tabs {
    margin-left: "3px";
    position: "relative";
    font-size: 15px;
    font: "normal normal normal  roboto";
  }
  .uploadMci {
    width: 32%;
    padding: "0px 0px 0px 0px";
    left: -12px;
    border-radius: "9px";
  }
  .attachment {
    /* height: 50%;  */
    width: 100%;
    /* border:1px solid #f1f1f1; */
  }
  .attachment-3 {
    height: 100%;
    width: 100%;
    grid-column: 1 / 3;
  }
  .uploadImage {
    margin-right: 35px;
  }
  .feedModel {
    width: 100%;
    overflow-y: scroll !important;
    height: 99px;
  }
  .feedsLikeModel {
    width: 30%;
    overflow-y: scroll !important;
    height: 95%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.signIn-header {
  font-size: 23px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.dashboard-header {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px 5px 0px;
}

.otp-container {
  display: flex;
  justify-content: center;
}

.otp-resend {
  display: "flex";
  justify-content: space-around;
  margin-top: "10px";
}
.terms-condition {
  color: green;
}
.otp {
  width: "32px";
}
.MuiOutlinedInput-input {
  padding: 18.5px 14px;
  width: 300px;
}
.dr-container {
  margin-top: 20px;
  margin-bottom: 10px;
}
.dr-dropdown {
  height: 45px;
  margin-right: 10px;
  border: 1px solid #ffffff;
  border-radius: 0px;
  background-color: #ffffff;
  font-size: 16px;
  width: 70px;
  border-radius: 10px;
}
.caret {
  color: red;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 29px;
}
.glyphicon {
  position: relative;
  top: 36px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  padding: 5px 5px 5px 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.PhoneInputInput {
  height: 56px;
  border: 1px solid #cabfbf;
  border-radius: 5px;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.like-user {
  margin-left: 10px;
  font: normal normal normal 16px/16px roboto;
  letter-spacing: 0px;
  color: #494949;
}
.PhoneInput {
  display: flex;

  align-items: center;
}
.text-danger {
  color: #e71515 !important;
  /* background-color: #ffcbcb; */
  /* border-radius: 5px; */
  margin-bottom: 10px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  padding: 0px 0px 0px 28px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2c2d;
  border-color: #2c2c2d;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  border-radius: 9px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.login-image {
  margin-top: 25px;
  margin-left: 80px;
  margin-bottom: 100px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 35;
  width: 35;
  outline: black;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: 100%, 100%;
  border-radius: 50%;
  color: black;
}
.form-group {
  margin-top: 2rem;
  width: auto;
  /* height: 45px; */
}
.error-title {
  background-color: white;
  border-radius: 9px;
  margin: 1em 0;
}
.error-title-text {
  margin-left: 10px;
  color: red;
  background-color: white;
}
.verify-otp {
  width: "200px";
  height: "45px";
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 15px);
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 0px 0px 0px 28px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control1 {
  display: block;
  width: 1%;
  height: calc(1.5em + 0.75rem + 15px);
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.signup-form {
  height: calc(1.5em + 0.75rem + 15px);
}

.signup-container {
  height: 100%;
  display: flex;
  justify-content: left;
  margin: 20px 0px 20px 0xp;
}
.PhoneInputCountry {
  border: 1px solid gray;
  background-color: #f6f6f6;
  border-radius: 10px;
  width: 50px;
  padding-left: 10px;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}
.header-tag {
  margin-top: 10px;
  margin-bottom: 5px;
}
.signup-title {
  display: flex;
  justify-content: left;
  margin-top: 10%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  margin-bottom: 0;
}
.bg-image {
  background-image: 'url("./images/logo/src_assets_images_latest_Main_bg_2X.png")';
  background-size: "100%";
}

.personal-info {
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px 0px;
}
.personal-background {
  background-color: #cee0dd;
  border-radius: 2px;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  color: white;
  border-radius: 50%;
  display: inline-block;
}
.signin {
  color: red;
  text-decoration: underline;
}
.react-responsive-modal-modal {
  border-radius: 5px;
}

.otpModal {
  width: 20em;
  border-radius: 15px;
}

.feedModel {
  width: 80%;
  left: -12px;
  height: 95%;
}
.postModel {
  width: 45%;
  height: 96%;
}
.profileModel {
  width: 45%;
  height: 75%;
}
@media only screen and (max-width: 768px) {
  .feedModel {
    width: 100%;
    overflow-y: scroll !important;
    height: 999px;
  }
  .profileModel {
    width: 90%;
    height: 300px;
    overflow-y: scroll;
  }
  /* .search-header{
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 62px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-header-dis{
    background-color: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    top: 106px !important;
    display: flex !important;
    border-radius: 18px !important;
    margin: 10px 0px !important;
    height: 40px !important;
    padding: 10px !important;
    border: 1px solid rgb(30, 138, 121) !important;
    background-color: aliceblue !important;
    z-index: 1 !important;
  }
  .search-button{ 
    font-size: 40px;
    margin-left: 89%;
    top:-8px;
    position: absolute
  }
  .close-button{ 
    font-size: 40px;
    margin-left: 83%;
    top:-8px;
    position: absolute
  } */

  /* .row{
    display : -webkit-inline-box
  }  */
}

.react-responsive-modal-modal.feedModel {
  max-width: 1100px;
  padding: 0px;
}

.verificationModel {
  padding: "0px 0px 0px 0px";
}

.iframe-loader {
  background: url(./assets/loading.gif) center center no-repeat;
}

.uploadMciLearn {
  width: 50%;
  padding: "0px 0px 0px 0px";
  left: -15px;
  border-radius: "9px";
}

.login-button {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.file {
  background-color: #0fb59b;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 9px;
  color: white;
}

.file > input[type="file"] {
  display: none;
}
.mci-background {
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.account-setup {
  background-color: "#ffffff";
  border: 1px solid gray;
  color: "black";
  padding: "11px";
  width: "205px";
  font-size: "12px";
  margin-bottom: "30px";
  border-radius: "9px";
}
.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

.file--uploading > label {
  color: hsl(48, 100%, 67%);
  border-color: hsl(48, 100%, 67%);
}

.file--uploading > label > i {
  animation: pulse 5s infinite;
}

.file--uploading > label:hover {
  border-color: hsl(48, 100%, 67%);
  background-color: hsl(48, 100%, 96%);
}

.file--uploading > label:active {
  background-color: hsl(48, 100%, 91%);
}
.custom-control-label:before {
  background-color: red;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: black;
}
.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  /* font: icon; */
}
.modeldashboard {
  display: none;
}

.checkbox-round {
  width: 1em;
  height: 1em;
  background-color: transparent;
  border-radius: 50%;
  /* vertical-align: middle; */
  border: 2px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  /* cursor: pointer; */
}

.checkbox-round:checked {
  background-color: #ffee00;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #f3969a;
}
option:hover {
  background-color: yellow;
}
select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}
.vl {
  border-left: 6px solid green;
}
.personal-title {
  font-size: 14px;
  font-weight: 400;
  color: black;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.profile-verification {
  display: flex;
  justify-content: space-between;
  font: normal normal normal 18px/20px Roboto;
}
.verification-icon {
  padding: 0px 3px 0px 5px;
}

.verification-logo {
  padding: 0px 5px 0px 3px;
}
.mobile-logo,
.mobile-header-profile {
  width: 80px;
  height: 100%;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}
.hr {
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* border: 0; */
  /* border-top: 1px solid rgba(0,0,0,.1); */
}
.posted-author {
  margin-left: 0px;
}
.dashboardtitle {
  color: #484848;
  font: normal normal medium 12px/26px Roboto;
  font-size: 12px;
  font-weight: 400;
}
.personaltitle {
  font: normal normal normal 14px/26px Roboto;
  letter-spacing: 0px;
  color: #484848;
  font-weight: 500;
  font-size: 14px;
}
.profile {
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  bottom: -40px;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  border: 1px solid green;
}
.profile-image {
  margin-left: 0px;
  margin-top: 0px;
}
.article-image {
  margin-left: 0px;
  margin-top: 0px;
}
.card-profile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
}
/* .feed-card {
  background-color: white;
  border: none;
  border-radius: 0;
  border-radius: 9px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 1px 15px 0px rgb(51 51 51 / 20%);
  box-shadow: 0px 1px 15px 0px rgb(51 51 51 / 20%);
}
.feeds-title {
  font: normal normal bold 15px/26px Roboto;
  color: #494949;
} */
.vl {
  border-left: 6px solid green;
  height: 500px;
}
.feed-profile {
  padding: 15px 5px 5px 10px;
}
/* .feed-title {
  padding: 0px 0px 0px 15px;
  color: #443e3e;
  font-weight: 500;
} */
.feed-user {
  margin-left: 10px;
  font: normal normal normal 16px/20px roboto;
  letter-spacing: 0px;
  color: #494949;
}
.feed-type {
  font-size: "11px";
  font-weight: "400";
  margin-left: 10px;
  color: black;
  margin-left: 10px;
}
.feed-info {
  background-color: #ff6e40;
  border-radius: 9px;
  padding: 3px 5px 3px 5px;
  font: normal normal medium 11px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}
.profile-thumb-middle {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #1e8a79;
}
.profile-thumb-like {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  /* border: 1px solid #1e8a79; */
}
.model-dialog {
  padding: 3px 0px 0px 25px;
  align-items: center;
  justify-content: start;
}
.feed-fullview {
  display: flex;
  justify-content: space-between;
}
.verification-title {
  color: #c3c30f;
  text-align: center;
  font: normal normal normal 16px/24px Roboto;
}
.verification-sub-title {
  color: black;
  text-align: center;
  font: normal normal normal 16px/24px Roboto;
}
.share-mobile {
  color: #707070;
  font: normal normal normal 7px/27px Segoe UI;
}
.copy-link {
  padding: 0px 0px 0px 5px;
}
.MuiDialog-paperWidthSm {
  max-width: 1000px;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  width: 400px;
}
.not-allowed {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
.v1 {
  border-left: 6px solid green;
  height: 500px;
}
.comment-author {
  background-color: #ececec;
  margin: 20px 0px 10px 0px;
  padding: 5px 0px 0px 0px;
  border-radius: 5px;
}

.feed-discription {
  display: inline-block;
  position: relative;
  color: #404040;
  font-size: 12px;
}

.share-title {
  margin-left: 10px;
  color: #707070;
  font: normal normal normal 14px/27px Segoe UI;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.test {
  position: absolute;
  bottom: 10px;
}
.responsive-header {
  display: -webkit-box;
  margin-top: 15px;
}
.responsive-footer {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  position: fixed;
  background-color: #ffffff;
}
.footer-title-mobile {
  font: normal normal normal 13px/15px Roboto;
  letter-spacing: 0px;
  color: #888888;
  cursor: pointer;
}

.left {
  background-color: #fc6e51;
  text-align: center;
  height: 100%;
}

.middle {
  background-color: lightgreen;
  text-align: center;
  height: 100%;
}

.right {
  height: 100%;
  background-color: #4fc1e9;
  text-align: center;
}
.footer {
  flex-shrink: 0;
  text-align: center;
  bottom: 0;
  color: white;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #0001f5;
}
.MuiAppBar-colorPrimary {
  color: #131212;
  background-color: #ffffff;
}
.profile-help-card {
  padding: 0px;
  border: 1px solid #afaaaa;
}

.help-container {
  padding: 20px 0px 0px 20px;
}

.help-title {
  font-family: normal normal medium Roboto;
  color: #4b4b4b;
  font-size: 22px;
  font-weight: 600;
}

.MuiFilledInput-input {
  padding: 27px 12px 10px;
  background-color: white;
  border: 1px solid gray;
}
.whitecoats-info {
  color: #2a8f80;
  font: normal normal medium 20px/24px Roboto;
  margin-left: 15px;
}
.connection-title {
  font: normal normal bold 18px/20px Roboto;
  color: #2a8f80;
}
.timeline-followers {
  font: normal normal bold 18px/20px Roboto;
  margin-left: 5px;
  color: #484848;
}
.following {
  margin-left: 5px;
}
.responsive-registration-tabs {
  margin-left: "5px";
  position: "relative";
  top: "3px";
  font: "normal normal normal 12px roboto";
}
.timeline-title {
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0px;
}
.speciality-title {
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
  color: #484848;
}
.contact-title {
  font: normal normal normal 20px/20px Roboto;
  letter-spacing: 0px;
  color: #4b4b4b;
}
.timeline-about {
  font: normal normal normal 18px/20px Roboto;
  letter-spacing: 0px;
  color: #4b4b4b;
}
.profile-card {
  border: 0.1px solid #c0c5c5;
  border-radius: 0;
  padding: 5px;
  background-color: white;
}
.footer-conatiner {
  display: flex;
  justify-content: space-between;
}
.profile-footer-conatiner {
  display: flex;
  justify-content: center;
}
.footer-icon {
  height: "20px";
  width: "30px";
  margin-right: "5px";
  cursor: pointer;
}

.footer-title {
  font: normal normal normal 18px/30px Roboto;
  letter-spacing: 0px;
  color: #888888;
}
.timeline-cover {
  background-position: center;
  background-size: cover;
  min-height: 237px;
  border-radius: 0 0 4px 4px;
  position: relative;
}
.timeline-cover .timeline-nav-bar {
  /* background: rgba(0, 0, 0, 0.7); */
  width: 100%;
  position: absolute;
  bottom: 25px;
}
.timeline-cover .timeline-nav-bar .profile-info {
  position: absolute;
  text-align: center;
  padding: 0 20px;
  top: -60px;
  margin-left: 20px;
  /* z-index: 999; */
}
.timeline-cover .timeline-nav-bar .profile-info img.profile-photo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.timeline-cover-mobile .timeline-nav-bar .profile-info.responsive-image {
  margin-top: 20px;
  width: 100px;
  border-radius: 50%;
  border: 10px solid #fff;
}
.profile-setting {
  cursor: "pointer";
  color: "#8D8D8D";
  padding: "10px";
  font-size: "12px";
}
.profile-copy {
  font: normal normal normal 18px/28px Roboto;
  letter-spacing: 0px;
  color: #646363;
  background-color: #f9f9f9;
  margin-left: 10px;
  margin-right: 20px;
}

.profile-thumb-small {
  display: block;
  overflow: hidden;
}
.fullview-iocns {
  display: flex;
}
.link {
  color: #888888;
}
.link:hover {
  color: #1e8a79;
  text-decoration: underline;
}
.followUs-container {
  display: flex;
  justify-content: space-between;
}
.mL {
  z-index: 1 !important;
}
.row-flex {
  flex-wrap: initial;
}
.padding-20px-bottom {
  padding-bottom: 20px;
}
.padding-35px-top {
  padding-top: 35px;
}
.bg-site-main {
  background-color: #2a8f80;
}
.margin-30px-bottom {
  margin-bottom: 30px;
}
.font-weight-600 {
  font-weight: 600;
}
.text-white {
  color: #fff;
}
.alt-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 25px;
  font-size: 25px;
  line-height: 30px;
}

.text-white {
  color: #fff;
}
.text-medium {
  font-size: 16px !important;
  line-height: 23px;
  font-family: "Arial";
  font-weight: 300;
  margin: 0 0 0 0;
  text-align: left;
  padding-bottom: 1.27em;
}
.text-center {
  text-align: center;
}
.button-cat {
  /* border: 1px solid #E5E8EC !important;
    border-radius: 20px !important;
    color: #007FFF !important;
    background: #8ebdbd !important;     */
  margin-bottom: 25px;
  height: 74px;
  width: 67px;
  background-color: #e9fffb;
  border-radius: 14px !important;
  word-break: break-word;
}
/* .button-cat:hover {
  background-color: yellow;
} */

.category-text {
  text-transform: none;
  display: flex;
  /*margin-top:8px;
  font:normal normal bold 12px/12px Roboto;
  color:#404040;
  line-height: 20px; 
  margin-top:8px;*/

  font: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.css-pwyk3z-MuiBadge-badge {
  background-color: red;
}
.badge-cat {
  width: 40px;
  height: 40px;
  margin-top: 5px;
}
.tab-cat {
  text-transform: none;
  margin-top: 5px;
  padding: 10px 8px;
  min-width: 0px;
}
.button-div {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  padding: 14px 12px;
  border-radius: 4px;
}
.fab {
  position: absolute !important;
  z-index: 1 !important;
  top: -30px !important;
  left: 0px !important;
  right: 30px !important;
  margin: 0px auto !important;
  height: 45px !important;
  width: 45px !important;
  background-color: white !important;
  border-radius: 50% !important;
}
.jobTitle {
  color: "black";
  margin-top: 10px;
}
.contactDetails {
  margin-bottom: 246px;
}
.forms_container {
  padding: 0px 25px;
}

.personalInfo_header{
  position: "fixed";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: "white";
  z-index: 10;
  display: "flex";
  flex-direction: "column";
  height: "100%";
  width: "100vw";
}
.profile-save-button{
  background-color: #469b8d;
  color: white;
  border-radius: 30px;
  height: 2.5em;
  width: 8em;
}.mobile-profile-save{
  background-color: #469b8d;
  color: white;
  border-radius: 30px;
  height: 2.5em;
  width: 20em;
}
.profile-close-button{
  background-color: white;
  border-radius: 30px;
  height: 3em;
  width: 8em;
  margin-left: 3em;
  border: 1px solid rgb(30 138 121);
  padding: 1rem 1.7rem;
  border-radius: 30px;
}
.profile-pic{
  display: flex;
}
.personalInfo_container_mobile{
  overflow: scroll;
}

.profile-save{
   margin: "30px 0px";
   display: flex;
   justify-content: center;
   margin-top: 30px;
  }
  .profile_pic_container{
    display: flex;
    justify-content: center;
  }