.community-feed-title {
  color: black;
  cursor: pointer;
}

.community-feed-info-container {
  display: flex;
  margin: 1em;
}

.community-feed-poster-container {
  display: flex;
}

.community-social-interaction {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 3em;
}

.community-social-button {
  display: flex;
}

.community-social-icons {
  margin-right: 0.6em;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.community-interaction-like {
  font: normal normal normal 18px/22px Roboto;
  margin-top: 4px;
  cursor: pointer;
  color: #515050;
}

.community-interaction-share {
  font: normal normal normal 18px/22px Roboto;
  cursor: pointer;
  color: #515050;
}

.media-tray-container {
  margin: 1em 0.5em;
}
.media-tray-header {
  font-size: 1.5em;
  color: green;
}

.media-tray {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media-thumbnail-container {
  height: 100px;
  width: 100px;
}

.media-thumbnail {
  height: 100px;
  width: 100px;
  border: 1px solid black;
}

.media-gallery-header {
  color: #999191;
  font-size: 1.3em;
  margin: 5px 0;
}

.media-gallery {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(3, 1fr);
}

.channel-file-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 300px;
  background: rgba(0, 0, 0, 0.8);
  padding: 1px;
}

@media (min-width: 768px) {
  .media-thumbnail-container {
    height: 150px;
    width: 160px;
  }
  .media-thumbnail {
    height: 150px;
    width: 160px;
  }
  .channel-file-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    background: rgba(0, 0, 0, 0.8);
    padding: 1px;
  }
}
