.error-page {
    display: table;
    text-align: center;
    width: 100%;
    height: 100vh;
  }
  
  .error-page .error-content {
    display: table-cell;
    vertical-align: middle;
  }
  
  .error-page .error-content img {
    margin: auto;
  }
  
  .error-page .error-content h1.error-title {
    font-family: "Agency FB";
    color: #6d6e71;
    font-size: 60px;
  }
  
  .error-page .error-content p {
    font-size: 16px;
  }
  
  .error-page .error-content form .form-group {
    width: 50%;
    margin: 0 auto 15px;
  }
  
  .error-page .error-content form .form-group .form-control {
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  