.timeline-cover-mobile {
  background-position: center;
  background-size: cover;
  min-height: 127px;
  border-radius: 0 0 4px 4px;
  position: relative;
}
.mobile-profile-photo {
  width: 90px;
  border-radius: 50%;
  border: 10px solid #fff;
}
.profile-save-button {
  background-color: #469b8d;
  color: white;
  border-radius: 30px;
  height: 2.5em;
  width: 7em;
}
.profile-close-button {
  background-color: white;
  border-radius: 30px;
  height: 2.5em;
  width: 7em;
  margin-left: 3em;
  border: 1px solid rgb(30 138 121);
  padding: 1rem 1.7rem;
  border-radius: 30px;
}
.pub-save-button {
  background-color: #469b8d;
  color: white;
  border-radius: 30px;
  height: 3em;
  width: 10em;
  margin-left: 45px;
}
.pub-close-button {
  background-color: white;
  border-radius: 30px;
  height: 3em;
  width: 7em;
  color: grey;
  margin-left: 2em;
  border: 1px solid grey;
  padding: 1rem 1.7rem;
  border-radius: 30px;
}
.print-head {
  color: rgb(62 165 148);
}
.print-img {
  width: 15px;
  margin-bottom: 2px;
  margin-right: 10px;
}
.author-title {
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0;
  color: #484848;
  font-style: italic;
}
.view-more {
  background-color: #c6f2f7;
  border-radius: 30px;
  width: 100%;
  border: 1px solid rgb(30 138 121);
  border-radius: 5px;
}
.mPub {
  width: 50%;
  height: 40%;
  margin-top: 20%;
  margin-left: 25%;
  margin-bottom: 10%;
}
.pub-head {
  color: rgb(125 199 186);
  font: 20px/20px roboto;
  text-align: center;
}

.profile-card-container {
  box-shadow: 0px 0px 1px 0px;
  border: 1px solid #e8e8e8;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
}

.profile-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-family: Roboto;
  font-weight: 400;
  color: #4b4b4b;
}

.profile-card-entry {
  display: flex;
  align-items: center;
  /* margin-block: 0.8em; */
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.profile-card-entry-text {
  /* margin-inline: 1em; */
  margin-right: 1em;
  margin-left: 1em;
  font-size: 1.3rem;
  word-break: break-all;
}

.profile-card-entry-icon {
  width: 20px;
}
.edit-delete {
  display: flex;
  -webkit-justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -20px;
}
.edit-delete-model {
  height: 47%;
  /* background-color: #1e8a79; */
  text-align: center !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-top: 150px !important;
  max-width: 500px !important;
}

.qualificationsModal {
  height: 85%;
  width: 45%;
}

.aoiModal {
  width: 45%;
}

.deleteModal {
  width: 20em;
}

@media screen and (min-width: 728px) {
  .deleteModal {
    width: 500px;
  }

  .profile-card-entry-text {
    font-size: 1.6rem;
  }
  .profile-card-entry-icon {
    width: 25px;
  }
  .profile-card-entry {
    /* margin-block: 1.3em; */
    margin-top: 1.3em;
    margin-bottom: 1.3em;
  }
}

.container_flex {
  display: flex;
}
