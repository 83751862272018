.main {
  /* background-color: white; */
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 1em;
  box-shadow: 0 5px 5px lightgray;
}

.searchBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(30, 138, 121);
  width: 90%;
  border-radius: 5px;
  padding: 0.5em 0.8em;
  gap: 1em;
}

.searchInput {
  border: none;
  margin: 0 0.5em;
  flex: 1;
  min-width: 0;
}

.searchOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1em;
}

.searchButton {
  border-radius: 10px;
  /* background-color: slategray; */
  padding: 10px;
  font-size: 1.3rem;
  color: white;
}

.filters {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow-x: auto;
  /* background-color: white; */
  padding: 1em 0.5em;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filters::-webkit-scrollbar {
  display: none;
}

.filter {
  /* border: 1px solid black; */
  background-color: white;
  padding: 0.5em 1em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.feeds {
  overflow-y: auto;
  height: 63vh;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  padding-bottom: 5px;
  margin-right: -15px;
  padding-right: 7px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.feeds:hover {
  scrollbar-color: gray transparent;
}

.feeds::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.feeds::-webkit-scrollbar-track {
  border-radius: 10px;
}

.feeds:hover::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.feeds::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.feeds::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
}

.feed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  gap: 1em;
  margin-bottom: 1em;
  background-color: white;
  cursor: pointer;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
}

.feedImage {
  width: 150px;
  height: 100px;
  border: 0.5px solid black;
  /* flex: 1; */
}

.feedType {
  color: rgb(30, 138, 121);
  text-transform: uppercase;
  font-size: 1.3rem;
}

.feedHeader {
  display: flex;
  gap: 1em;
  align-items: flex-start;
}

.title {
  flex: 1;
}

.description {
  font-size: 1.3rem;
  color: slategray;
  /* margin-top: 1em; */
  flex: 1;
}
