.Channellogo{
    min-height: 400px;
    background-color:"red"
}
.about-title{
    font: normal normal normal 21px/20px Roboto;
    padding: 5px;
    color: #5c5a5a;
}
.about-discription{
    font: normal normal normal 18px/18px Roboto;
    padding: 5px;
    color: black;
    white-space: pre-line;
}
.about-contact{
    font: normal normal normal 18px/20px Roboto;
    padding: 5px;
}
.discription{
    color: #1b8c7a;
    text-decoration: underline;
    cursor: pointer;
}
.member-title{
    /* font: normal normal normal 18px/20px Roboto; */
    padding: 5px;
    color: rgb(30, 138, 121);
    font: bold 15px / 15px roboto;
}