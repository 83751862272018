.form-navigation {
  background-color: rgba(255, 255, 255, 0.67);
  border-radius: 5px;
  /* text-align: center; */
  display: flex;
  font-size: 0.9em;
  /* align-content: center; */
  height: 2em;
}

.form-navigation-element-active {
  display: inline-block;
  flex-grow: 1;
  background-color: white;
  opacity: 1;
  height: 100%;
  border-radius: 5px;
  color: #1b8c7a;
  padding: 5px;
}

.form-navigation-element {
  display: inline-block;
  flex-grow: 1;
  height: 100%;
  padding: 5px;
  color: black;
}

.signTitle {
  font-size: "20px";
  color: "white";
  font-weight: "700px";
  cursor: "pointer";
}
.sign-in {
  margin-right: "30px";
}
.sign-active {
  border: "1px solid #FFEE00";
  background-color: "#FFEE00";
  opacity: "1";
  width: "100%";
  height: "0px";
}

.signup-name-row {
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(160px, 1fr));
  gap: 15px;
}

/* .signup-lastname {
  border-radius: 9px;
  background: #f6f6f6;
  height: 3.5em;
  width: 100%;
} */

@media (min-width: 575px) and (max-width: 1131px), (max-width: 440px) {
  .signup-lastname {
    grid-column: 1 / -1;
  }
}
