.filter-header {
  color: #8f8f8f;
}

.inline-header {
  display: flex;
  padding: 3px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
  margin-bottom: 1em;
}

.filter {
  width: 200px;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 575px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .filter {
    width: 130px;
  }
}
