.feed-card {
  background-color: white;
  border: 1px solid #e8e8e8;
  /* remove side margin later */
  margin-bottom: 2em;
  border-radius: 6px;
  padding-bottom: 1em;
  word-break: break-word;
}

.mandatoryInfoPopUpContainer {
  padding: 1em 2em;
}

@media (max-width: 768px) {
  .mandatoryInfoPopUpContainer {
    padding: 3em 0 1.5em;
  }
}

.feed-header {
  display: flex;
  align-content: center;
  padding: 1em;
  width: fit-content;
  border-radius: 50%;
}

.feed-header-image {
  height: 3.5em;
  width: 3.5em;
  border-radius: 50%;
  border: 1px solid green;
}

.feed-info-container {
  width: 92%;
  margin: 0 1em;
}

.feed-content {
  padding: 0 1em;
}

.feed-poster {
  font: normal normal normal bold 16px/19px Roboto;
  margin-top: 7px;
  color: #333333;
}
.dashboard-feed-discription {
  font: normal normal normal 15px/22px Roboto;
  color: #404040;
  overflow-wrap: break-word;
}
.profile-verification-progress {
  font: normal normal normal 14px/21px Roboto;
  cursor: pointer;
  margin-left: 7px;
}
.feed-type {
  font: normal normal normal 12px/22px Roboto;
  color: #404040;
  width: max-content;
  font-size: 0.7em;
  color: #484848;
  margin: 0;
  padding: 3px 4px 3px 3px;
  margin-left: 2px;
}
.feed-like-user {
  margin-left: 10px;
  font: normal normal normal 16px/16px roboto;
  letter-spacing: 0px;
  color: #494949;
}

.fullview-feed-user {
  margin-left: 10px;
  font: normal normal normal 16px/20px roboto;
  letter-spacing: 0px;
  color: #333;
}
.feed-like-type {
  font: normal normal normal 12px/5px Roboto;
  width: max-content;
  color: #484848;
  margin: 0;
  padding: 3px 4px 3px 0px;
  margin-left: 5px;
}
.fullview-feed-type {
  font: normal normal normal 12px/22px Roboto;
  color: white;
  width: max-content;
  font-size: 0.7em;

  margin: 0;
  padding: 3px 4px 3px 3px;
  margin-left: 2px;
}
.like-container {
  margin-left: 5px;
  padding: 5px 0px 0px 0px;
  border-radius: 5px;
}
.like-container-mobile {
  padding: 5px 0px 0px 0px;
  border-radius: 5px;
  margin: 0px 0px 5px 0px;
}
.verification {
  display: flex;
  justify-content: center;
}

.feed-date {
  font: normal normal normal 10px/15px Roboto;
  letter-spacing: 0px;
  color: #888888;
  margin: 2px;
}
.dashboard-feed-type {
  font: normal normal normal 9px/16px Roboto;
  width: max-content;
  background-color: #ff6e40;
  color: white;
  margin: 0;
  padding: 2px 4px 2px 4px;
  border-radius: 9px;
}
.feed-title {
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0px;
  color: #404040;
  margin-bottom: 0.5em;
  overflow-wrap: break-word;
}
.fullview-feeds-title {
  font: normal normal normal 17px/20px roboto;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 10px;
  color: #484848;
  white-space: pre-line;
}

.feed-description {
  font: normal normal normal 16px/25px Roboto;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  position: relative;
  color: #404040;
  margin-bottom: 10px;
  font-size: 1.1em;
}
.categoryHeader {
  margin-top: "20px";
  color: "white";
  font: "normal normal medium Roboto";
  font-size: "20px";
}

.read-more {
  font: normal normal normal 16px/22px Roboto;
  position: absolute;
  bottom: 6px;
  right: 0;
  z-index: 3;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  height: 1.2em;
}

.feed-attachments-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(80px, min-content);
  grid-auto-rows: 150px;
  gap: 5px;
}

.feed-image-container {
  position: relative;
}
.feed-image-container .show-more-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
}

.feed-image-container:hover .show-more-images {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.feed-image-hover-text {
  color: white;
  position: absolute;
  top: 35%;
  left: 35%;
  font-size: 3em;
}

.attachment-mobile {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
}

.social-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;

  font: normal normal normal 18px/22px Roboto;
}
.commentButton {
  font-size: 13px;
  margin-top: 10px;
}
.interaction-comment-mobile {
  font: normal normal normal 12px/22px Roboto;
  color: #515050;
}
.interaction-like-mobile {
  font: normal normal normal 12px/22px Roboto;
  color: #515050;
}

.interaction-like {
  font: normal normal normal 18px/22px Roboto;
  margin-top: 6px;
  margin-left: 2px;
  margin-right: 3px;
  color: #515050;
  cursor: pointer;
}
.interaction-comment {
  font: normal normal normal 18px/22px Roboto;
  margin-top: 1px;
  margin-left: 2px;
  margin-right: 3px;
  color: #515050;
}
.interaction-share {
  font: normal normal normal 18px/22px Roboto;
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 3px;
  color: #515050;
}
.social-icons {
  height: 80%;
  margin-top: 2px;
  /* padding: 0 5px; */
  margin-right: 0.6em;
}
.social-icons-mobile {
  width: 20%;
  /* padding: 0 5px; */
  margin-right: 0.3em;
}
.people-like {
  font-size: 14px;
}
.social-interaction {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.5em 1em;
}

.comment-input-box {
  /* padding: 0 1em; */
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.comment {
  padding: 0 1em;
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.comment-input {
  border: none;
  background: inherit;
  width: 80%;
}

.comment-input-container {
  background-color: #d1d1d1;
  border-radius: 3px;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.3em;
  height: 3em;
  margin-left: 0.5em;
}

.comment-image {
  height: 2.5em;
  width: auto;
  border: 1px solid green;
  border-radius: 50%;
}

.like-image {
  height: 2.5em;
  width: auto;
  /* border: 1px solid green; */
  border-radius: 50%;
}

.comment-info-container {
  background-color: #f1f1f1;
  border-radius: 3px;
  width: 92%;
  padding: 0.3em 1em;
  margin-left: 0.5em;
}

.comment-input-image {
  margin: 0.2em 0.3em 0.1em 0.3em;
  height: 80%;
}

.comment-poster {
  font: normal normal normal 16px/20px Roboto;
}

.comment-date {
  font-size: 10px;
  margin-bottom: 9px;
}
/* a:visited {
  color: green;
} */

/* mouse over link */
/* a:hover {
  color: hotpink;
} */

/* selected link */
/* a:active {
  color: blue;
} */
.comment-text {
  font: normal normal normal 14px/20px Roboto;
  height: 1.2em;
  overflow: hidden;
}

.comment-attachments {
  height: 10em;
  width: 10em;
}
.upload-title {
  color: #737171fc;
  font-size: 15px;
  font-weight: 600;
  margin-left: 20px;
}
.upload-sub-title {
  color: #737171fc;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
}
.verifaction-title {
  margin-top: "7px";
  margin-top: 10px;
}
.upload-container {
  background-color: "#F6F6F6";
  border-radius: "0px 0px 5px 5px";
  height: "50px";
  display: "flex";
  align-content: "center";
  justify-content: "flex-end";
  z-index: "3";
}
.profile-count {
  color: rgb(30, 138, 121);
  text-align: "left";
  padding-left: "25px";
  font-weight: "700";
  font: " normal normal normal bold 16px/17px roboto";
}
.mobilePdf {
  overflow: "auto";
  -webkit-overflow-scrolling: "touch";
}
.testPdf {
  width: 100%;
  height: 100%;
  overflow: auto !important;
}
